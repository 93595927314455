import * as React from 'react'

import LayoutRoot from '../components/LayoutRoot'
import SuccessCase from '../components/SuccessCase'
import ContactUs from '../components/ContactUs'

const SuccessCases = ({ pageContext: { successcases } }: { pageContext: { successcases: SuccessCase[] } }) => {
  return (
    <LayoutRoot isWhiteCredential>
      <>
        <SuccessCase cases={successcases} isDetail />
        <div style={{marginTop: 50}} />
        <ContactUs />
      </>
    </LayoutRoot>
  )
}
export default SuccessCases
